<template>
    <div class="actions">
      <img :src="photo" class="avatar-xl rounded-circle"/>
    </div>
  </template>
    
    <script>
    const placeholder = import('../../../assets/img/logo.png')
    export default {
        props: {object: {type: Object, required: true}},
        data(){
            return  {
                placeholder
            }
        },
        computed: {
            hasPhoto(){
                if(this.object === null || this.object === undefined) return false
                return this.object.photo !== null && this.object.photo !== undefined
            },
            photo(){
                if(this.hasPhoto) {
                    let p = JSON.parse(this.object.photo)
                    return p.url
                }
                return this.placeholder
            }
        }
    }
    </script>
    
    <style>
    
    </style>